var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showInformations,
              expression: "showInformations",
            },
          ],
          staticClass: "wrapper",
        },
        [
          _c("div", { staticClass: "wrapper__card" }, [
            _c("div", { staticClass: "wrapper__card__header" }, [
              _vm._m(0),
              _c("div", { staticClass: "right" }, [
                _c(
                  "div",
                  [
                    _c("z-input", {
                      attrs: {
                        placeholder: "Pesquisar",
                        prependIcon: "$search_rounded",
                        type: "text",
                        hideDetails: true,
                      },
                      model: {
                        value: _vm.searchConnection,
                        callback: function ($$v) {
                          _vm.searchConnection = $$v
                        },
                        expression: "searchConnection",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("z-btn", {
                      attrs: {
                        rounded: "",
                        primary: "",
                        text: "Novo",
                        icon: "mdi-plus",
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.contextMenu.open($event, "data")
                        },
                      },
                    }),
                    _c("z-context-menu", {
                      ref: "contextMenu",
                      attrs: { options: _vm.optionsMenu },
                      on: { click: _vm.onClickContextAction },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("z-btn", {
                      attrs: {
                        small: "",
                        rounded: "",
                        text: "ir para jornada",
                        icon: "mdi-arrow-collapse-right",
                        iconRight: true,
                      },
                      on: {
                        click: function ($event) {
                          _vm.showAudienceModal = true
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { ref: "cardBody", staticClass: "wrapper__card__body" },
              [
                _c("z-pagination", {
                  attrs: {
                    "auto-pagination": "",
                    "icon-previous": "mdi-chevron-left",
                    "icon-next": "mdi-chevron-right",
                    "items-per-page": _vm.getNumberOfElements,
                    "number-of-items": _vm.searchConnection
                      ? _vm.filtered.length
                      : _vm.allTemplates.lenth,
                    items: _vm.searchConnection
                      ? _vm.filtered
                      : _vm.allTemplates,
                  },
                  on: { sort: _vm.sortTable },
                  scopedSlots: _vm._u([
                    {
                      key: "data",
                      fn: function ({ paginatedItems }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "table-wrapper" },
                            [
                              _c("z-table", {
                                staticClass: "table",
                                attrs: {
                                  headers: _vm.headers,
                                  items: paginatedItems,
                                  "icon-next": "mdi-chevron-right",
                                  "icon-previous": "mdi-chevron-left",
                                },
                                on: { sort: _vm.sortTable },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "no-data",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "no-data-table" },
                                            [
                                              _c("h3", [
                                                _vm._v(
                                                  "Sem dados disponíveis."
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: `item.favorite`,
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center justify-center",
                                            },
                                            [
                                              item.favorite
                                                ? _c("img", {
                                                    staticClass: "mr-1",
                                                    style: _vm.iconStyle,
                                                    attrs: {
                                                      src: "/icons/favorite-icon.svg",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.favoriteTemplate(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _c("img", {
                                                    staticClass: "mr-1",
                                                    style: _vm.iconStyle,
                                                    attrs: {
                                                      src: "/icons/favorite-not-filled-icon.svg",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.favoriteTemplate(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.templateName`,
                                      fn: function ({ item }) {
                                        return [
                                          _c("strong", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                item.favorite
                                                  ? _c("img", {
                                                      staticClass: "mr-1",
                                                      style: _vm.iconStyle,
                                                      attrs: {
                                                        src: "/icons/favorite-icon.svg",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.favoriteTemplate(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _c("img", {
                                                      staticClass: "mr-1",
                                                      style: _vm.iconStyle,
                                                      attrs: {
                                                        src: "/icons/favorite-not-filled-icon.svg",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.favoriteTemplate(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    }),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.templateName ||
                                                          "Sem registro"
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.createdAt`,
                                      fn: function ({ item }) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formateDate(item.createdAt)
                                              ) + " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.databaseConfigId`,
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getConnectionName(item)
                                            ) + " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.infobipWhatsappTemplateStatus`,
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "status d-flex align-center",
                                              staticStyle: { gap: "0.5rem" },
                                            },
                                            [
                                              item?.infobipWhatsappTemplateStatus
                                                ? _c(
                                                    "span",
                                                    {
                                                      style: _vm.getStatusStyle(
                                                        item.infobipWhatsappTemplateStatus
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getStatusName(
                                                              item.infobipWhatsappTemplateStatus
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.getStatusName(
                                                item.infobipWhatsappTemplateStatus
                                              ) == "Rejected"
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: {
                                                        bottom: "",
                                                        color: "grey",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                              attrs,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      "v-icon",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-information-outline"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "white",
                                                            padding: "0.5rem",
                                                            "font-weight":
                                                              "500",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Motivo para a rejeição do template"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.templateType`,
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              style: _vm.getChipStyle(
                                                item.templateType.toLowerCase()
                                              ),
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.templateType.toUpperCase()
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.actions`,
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "actions" },
                                            [
                                              ["sms", "email", "push"].includes(
                                                item.templateType
                                              )
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: {
                                                        bottom: "",
                                                        color: "grey",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                              attrs,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "mr-1",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.selectOptionFromTable(
                                                                                item,
                                                                                2
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      "v-icon",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "$account_tree"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "white",
                                                            padding: "0.5rem",
                                                            "font-weight":
                                                              "500",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Jornada rápida"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    bottom: "",
                                                    color: "grey",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "mr-1",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.selectOptionFromTable(
                                                                            item,
                                                                            1
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "$edit_outline"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "white",
                                                        padding: "0.5rem",
                                                        "font-weight": "500",
                                                      },
                                                    },
                                                    [_vm._v("Editar")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    bottom: "",
                                                    color: "grey",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "mr-1",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.duplicateTemplate(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "mdi-content-duplicate"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "white",
                                                        padding: "0.5rem",
                                                        "font-weight": "500",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Duplicar Template"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    bottom: "",
                                                    color: "grey",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "mr-1",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.selectOptionFromTable(
                                                                            item,
                                                                            0
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "$delete_outline_rounded"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "white",
                                                        padding: "0.5rem",
                                                        "font-weight": "500",
                                                      },
                                                    },
                                                    [_vm._v("Apagar")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c(
            "v-dialog",
            {
              attrs: { width: "900" },
              model: {
                value: _vm.showSelectJourneyModal,
                callback: function ($$v) {
                  _vm.showSelectJourneyModal = $$v
                },
                expression: "showSelectJourneyModal",
              },
            },
            [
              _vm.showSelectJourneyModal
                ? _c("SelectJourneyModal", {
                    on: {
                      close: function ($event) {
                        _vm.showSelectJourneyModal = false
                      },
                      editJourney: _vm.editJourney,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("z-card-modal", {
            attrs: { width: "500", title: "Como você quer começar?" },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "cards-audience",
                        style: _vm.cardAudienceStyle2,
                      },
                      _vm._l(_vm.audienceModalData, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "cards-audience__item",
                            style: _vm.cardAudienceStyle,
                            on: {
                              click: function ($event) {
                                return _vm.chooseMethodToJourney(item.value)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "header",
                                staticStyle: { "text-align": "center" },
                              },
                              [
                                _c(
                                  "figure",
                                  {
                                    staticStyle: {
                                      width: "30px",
                                      height: "30px",
                                      margin: "0 auto",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                        "object-fit": "contain",
                                      },
                                      attrs: { src: item.icon },
                                    }),
                                  ]
                                ),
                                _c(
                                  "strong",
                                  {
                                    staticStyle: {
                                      "text-transform": "uppercase",
                                      "font-size": "0.85em",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "body",
                                staticStyle: {
                                  "text-align": "center",
                                  "font-size": "1em",
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(item.description))])]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.showAudienceModal,
              callback: function ($$v) {
                _vm.showAudienceModal = $$v
              },
              expression: "showAudienceModal",
            },
          }),
          _c("z-card-modal", {
            attrs: { width: "800", title: "Novo e-mail" },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "cards-audience",
                        style: _vm.cardAudienceStyle2,
                      },
                      _vm._l(_vm.emailModalData, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "cards-audience__item",
                            style: _vm.cardAudienceStyle,
                            on: {
                              click: function ($event) {
                                return _vm.goToPage(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "header",
                                staticStyle: { "text-align": "center" },
                              },
                              [
                                _c(
                                  "figure",
                                  {
                                    staticStyle: {
                                      width: "30px",
                                      height: "30px",
                                      margin: "0 auto",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                        "object-fit": "contain",
                                      },
                                      attrs: { src: item.icon },
                                    }),
                                  ]
                                ),
                                _c(
                                  "strong",
                                  {
                                    staticStyle: {
                                      "text-transform": "uppercase",
                                      "font-size": "0.85em",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "body",
                                staticStyle: {
                                  "text-align": "center",
                                  "font-size": "1em",
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(item.description))])]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "action d-flex justify-end py-2" },
                      [
                        _c("z-btn", {
                          attrs: { large: "", text: "cancelar" },
                          on: {
                            click: function ($event) {
                              _vm.emailDialog = false
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.emailDialog,
              callback: function ($$v) {
                _vm.emailDialog = $$v
              },
              expression: "emailDialog",
            },
          }),
          _c("z-card-modal", {
            attrs: { width: "500", title: "Novo push" },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "cards-audience",
                        style: _vm.cardAudienceStyle2,
                      },
                      _vm._l(_vm.pushModalData, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "cards-audience__item",
                            style: _vm.cardAudienceStyle,
                            on: {
                              click: function ($event) {
                                return _vm.goToPage(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "header",
                                staticStyle: { "text-align": "center" },
                              },
                              [
                                _c(
                                  "figure",
                                  {
                                    staticStyle: {
                                      width: "30px",
                                      height: "30px",
                                      margin: "0 auto",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                        "object-fit": "contain",
                                      },
                                      attrs: { src: item.icon },
                                    }),
                                  ]
                                ),
                                _c(
                                  "strong",
                                  {
                                    staticStyle: {
                                      "text-transform": "uppercase",
                                      "font-size": "0.85em",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "body",
                                staticStyle: {
                                  "text-align": "center",
                                  "font-size": "1em",
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(item.description))])]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "action d-flex justify-end py-2" },
                      [
                        _c("z-btn", {
                          attrs: { large: "", text: "cancelar" },
                          on: {
                            click: function ($event) {
                              _vm.pushDialog = false
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.pushDialog,
              callback: function ($$v) {
                _vm.pushDialog = $$v
              },
              expression: "pushDialog",
            },
          }),
          _c("z-card-modal", {
            attrs: { width: "500", title: "Selecionar sms" },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "cards-audience",
                        style: _vm.cardAudienceStyle2,
                      },
                      _vm._l(_vm.smsModalData, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "cards-audience__item",
                            style: _vm.cardAudienceStyle,
                            on: {
                              click: function ($event) {
                                return _vm.goToPage(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "header",
                                staticStyle: { "text-align": "center" },
                              },
                              [
                                _c(
                                  "figure",
                                  {
                                    staticStyle: {
                                      width: "30px",
                                      height: "30px",
                                      margin: "0 auto",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                        "object-fit": "contain",
                                      },
                                      attrs: { src: item.icon },
                                    }),
                                  ]
                                ),
                                _c(
                                  "strong",
                                  {
                                    staticStyle: {
                                      "text-transform": "uppercase",
                                      "font-size": "0.85em",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "body",
                                staticStyle: {
                                  "text-align": "center",
                                  "font-size": "1em",
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(item.description))])]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "action d-flex justify-end py-2" },
                      [
                        _c("z-btn", {
                          attrs: { large: "", text: "cancelar" },
                          on: {
                            click: function ($event) {
                              _vm.smsDialog = false
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.smsDialog,
              callback: function ($$v) {
                _vm.smsDialog = $$v
              },
              expression: "smsDialog",
            },
          }),
          _c("z-card-modal", {
            attrs: { width: "400", title: "" },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("div", { staticClass: "delete-confirmation" }, [
                      _c(
                        "div",
                        {
                          staticClass: "delete-confirmation__title mb-5",
                          staticStyle: {
                            "justify-content": "center",
                            display: "flex !important",
                          },
                        },
                        [
                          _c("span", [
                            _c("strong", [
                              _vm._v("Confirmar apagar este template?"),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "delete-confirmation--actions",
                          staticStyle: {
                            "justify-content": "space-evenly",
                            display: "flex",
                          },
                        },
                        [
                          _c("z-btn", {
                            attrs: { small: "", rounded: "", text: "cancelar" },
                            on: {
                              click: function ($event) {
                                _vm.dialogDelete = false
                                _vm.templateToBeDeleted = null
                              },
                            },
                          }),
                          _c("z-btn", {
                            attrs: {
                              rounded: "",
                              primary: "",
                              text: "apagar",
                              small: "",
                              isLoading: _vm.loadingDelete,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.confirmDeleteTemplate()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.dialogDelete,
              callback: function ($$v) {
                _vm.dialogDelete = $$v
              },
              expression: "dialogDelete",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showInformations,
              expression: "!showInformations",
            },
          ],
          staticClass: "wrapper no-data",
        },
        [_c("div", { staticClass: "wrapper__card" }, [_c("z-loading")], 1)]
      ),
      _c("z-card-modal", {
        attrs: {
          width: "600",
          title:
            "tem certeza que deixar criar uma nova jornada com esse template?",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "fast-journey-modal" }, [
                  _c("div", { staticClass: "pt-3 pb-5" }, [
                    _c("span", [
                      _vm._v(" Template selecionado: "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.fastJourneyCurrentTemplate.templateName)
                        ),
                      ]),
                    ]),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        " Selecione uma audiência abaixo para dar proseguimento "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-form",
                        { ref: "confirmFastForm" },
                        [
                          _c("z-autocomplete", {
                            attrs: {
                              label: "Audiência",
                              items: _vm.currentAudiencesList,
                              itemText: "name",
                              placeholder: "Pesquisar",
                              type: "text",
                              hideDetails: true,
                              returnObject: "",
                              rules: [(v) => !!v || "Campo obrigatório"],
                            },
                            model: {
                              value: _vm.choosenAudience,
                              callback: function ($$v) {
                                _vm.choosenAudience = $$v
                              },
                              expression: "choosenAudience",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "content__timer-type mb-5",
                          staticStyle: { "margin-top": "10px" },
                        },
                        [
                          _c("div", { staticClass: "repeat-each mb-0" }, [
                            _c("strong", { staticClass: "mb-2" }, [
                              _vm._v(" Tipo: "),
                            ]),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: {
                                rules: [(v) => !!v || "Campo obrigatório"],
                                "hide-details": "",
                                row: "",
                                small: "",
                              },
                              model: {
                                value: _vm.timerType,
                                callback: function ($$v) {
                                  _vm.timerType = $$v
                                },
                                expression: "timerType",
                              },
                            },
                            [
                              _c("v-radio", {
                                staticStyle: { width: "150px" },
                                attrs: { small: "", value: "punctual" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#000000" } },
                                          [_vm._v("Pontual")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                              _c("v-radio", {
                                staticStyle: { width: "150px" },
                                attrs: { value: "recurring" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#000000" } },
                                          [_vm._v("Recorrente")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.timerType == "punctual"
                        ? _c(
                            "section",
                            { staticClass: "content__punctual-info mt-3" },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  attrs: {
                                    rules: [(v) => !!v || "Campo obrigatório"],
                                    "hide-details": "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.executeTime = ""
                                    },
                                  },
                                  model: {
                                    value: _vm.startType,
                                    callback: function ($$v) {
                                      _vm.startType = $$v
                                    },
                                    expression: "startType",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    staticStyle: { width: "150px" },
                                    attrs: { value: "immediate" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#000000",
                                                  },
                                                },
                                                [_vm._v("Imediato")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1163317323
                                    ),
                                  }),
                                  _c("v-radio", {
                                    attrs: { value: "date" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "mr-5",
                                                  staticStyle: {
                                                    color: "#000000",
                                                  },
                                                },
                                                [_vm._v("Agendamento ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1725176911
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm.startType == "date"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                md: "6",
                                                sm: "6",
                                              },
                                            },
                                            [
                                              _c("z-input-date", {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  disabled:
                                                    _vm.startType != "date",
                                                  hideDetails: true,
                                                  min: _vm.minimumStartDate,
                                                  rules: [
                                                    (v) =>
                                                      !!v ||
                                                      "Campo obrigatório",
                                                  ],
                                                  format: "pt-BR",
                                                  label: "",
                                                  small: "",
                                                  type: "date",
                                                },
                                                model: {
                                                  value: _vm.punctualTimerType,
                                                  callback: function ($$v) {
                                                    _vm.punctualTimerType = $$v
                                                  },
                                                  expression:
                                                    "punctualTimerType",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "3",
                                              },
                                            },
                                            [
                                              _c("z-select", {
                                                attrs: {
                                                  hideDetails: true,
                                                  items:
                                                    _vm.actualSelectedDateIsToday
                                                      ? _vm.avaliableHours
                                                      : _vm.hoursArr,
                                                  readonly:
                                                    !_vm.punctualTimerType,
                                                  hint: "asdfasdf",
                                                  label: "Hora",
                                                  small: "",
                                                },
                                                model: {
                                                  value: _vm.hourInfo,
                                                  callback: function ($$v) {
                                                    _vm.hourInfo = $$v
                                                  },
                                                  expression: "hourInfo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "3",
                                              },
                                            },
                                            [
                                              _c("z-select", {
                                                attrs: {
                                                  hideDetails: true,
                                                  items:
                                                    _vm.actualSelectedDateIsToday
                                                      ? _vm.avaliableMinutes
                                                      : _vm.minutesArr,
                                                  readonly:
                                                    !_vm.punctualTimerType,
                                                  label: "Minuto",
                                                  small: "",
                                                },
                                                model: {
                                                  value: _vm.minuteInfo,
                                                  callback: function ($$v) {
                                                    _vm.minuteInfo = $$v
                                                  },
                                                  expression: "minuteInfo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.actualSelectedDateIsToday &&
                                          _vm.hourInfo &&
                                          _vm.minuteInfo
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "mt-3",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "small",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      _vm._v(
                                                        "*Agendado previamente para: "
                                                      ),
                                                      _c(
                                                        "strong",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                _vm.punctualTimerType
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "strong",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.hourInfo
                                                            ) +
                                                              ":" +
                                                              _vm._s(
                                                                _vm.minuteInfo
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.timerType == "recurring"
                        ? _c(
                            "section",
                            { staticClass: "content__recurring-info" },
                            [
                              _c(
                                "div",
                                { staticClass: "start mb-5" },
                                [
                                  _c("strong", { staticClass: "mb-0" }, [
                                    _vm._v(" Início"),
                                  ]),
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: {
                                        rules: [
                                          (v) => !!v || "Campo obrigatório",
                                        ],
                                        "hide-details": "",
                                        inline: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.startType != "date"
                                            ? (_vm.punctualTimerType = "")
                                            : ""
                                        },
                                      },
                                      model: {
                                        value: _vm.startType,
                                        callback: function ($$v) {
                                          _vm.startType = $$v
                                        },
                                        expression: "startType",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-radio", {
                                            staticStyle: {
                                              width: "150px",
                                              "margin-top": "10px",
                                            },
                                            attrs: { value: "immediate" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#000000",
                                                          },
                                                        },
                                                        [_vm._v("Imediato")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1163317323
                                            ),
                                          }),
                                          _c("v-radio", {
                                            attrs: { value: "date" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "9" },
                                                        },
                                                        [
                                                          _c("z-input-date", {
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              disabled:
                                                                _vm.startType !=
                                                                "date",
                                                              hideDetails: true,
                                                              rules:
                                                                _vm.startType ===
                                                                "date"
                                                                  ? [
                                                                      (v) =>
                                                                        !!v ||
                                                                        "Campo obrigatório",
                                                                    ]
                                                                  : [],
                                                              format: "pt-BR",
                                                              label: "",
                                                              type: "date",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.punctualTimerType,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.punctualTimerType =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "punctualTimerType",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              2091821971
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.startType == "date"
                                    ? _c(
                                        "div",
                                        [_c("v-row", { attrs: { dense: "" } })],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "end mb-5" },
                                [
                                  _c("strong", { staticClass: "mb-0" }, [
                                    _vm._v(" Fim "),
                                  ]),
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: {
                                        rules: [
                                          (v) => !!v || "Campo obrigatório",
                                        ],
                                        "hide-details": "",
                                        inline: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.punctualTimerTypeEnd = ""
                                        },
                                      },
                                      model: {
                                        value: _vm.endType,
                                        callback: function ($$v) {
                                          _vm.endType = $$v
                                        },
                                        expression: "endType",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-radio", {
                                            staticStyle: {
                                              width: "150px",
                                              "margin-top": "10px",
                                            },
                                            attrs: { value: "undefined" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#000000",
                                                          },
                                                        },
                                                        [_vm._v("Indefinido ")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              3296433528
                                            ),
                                          }),
                                          _c("v-radio", {
                                            attrs: { value: "date" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "9" },
                                                        },
                                                        [
                                                          _c("z-input-date", {
                                                            attrs: {
                                                              disabled:
                                                                _vm.endType !=
                                                                "date",
                                                              hideDetails: true,
                                                              min: _vm.minimumDate,
                                                              rules:
                                                                _vm.endType ===
                                                                "date"
                                                                  ? [
                                                                      (v) =>
                                                                        !!v ||
                                                                        "Campo obrigatório",
                                                                    ]
                                                                  : [],
                                                              format: "pt-BR",
                                                              label: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.punctualTimerTypeEnd,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.punctualTimerTypeEnd =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "punctualTimerTypeEnd",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              2062778523
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "repeat-each mb-0" }, [
                                _c("strong", { staticClass: "mb-2" }, [
                                  _vm._v(" Repetir a cada: "),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "repeat-each mb-5" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            lg: "3",
                                            md: "6",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c("z-input", {
                                            ref: "inptRef",
                                            staticClass: "custom_inpt",
                                            attrs: {
                                              "hide-details": true,
                                              rules: [
                                                (v) =>
                                                  !!v || "Campo obrigatório",
                                              ],
                                              itemText: "text",
                                              itemValue: "value",
                                              type: "number",
                                            },
                                            model: {
                                              value: _vm.every,
                                              callback: function ($$v) {
                                                _vm.every = $$v
                                              },
                                              expression: "every",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            lg: "4",
                                            md: "6",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c("z-select", {
                                            ref: "selectRef",
                                            attrs: {
                                              "hide-details": true,
                                              items: _vm.repeatItems,
                                              rules: [
                                                (v) =>
                                                  !!v || "Campo obrigatório",
                                              ],
                                              itemText: "text",
                                              itemValue: "value",
                                              "value-position": "center",
                                            },
                                            model: {
                                              value: _vm.repeatItem,
                                              callback: function ($$v) {
                                                _vm.repeatItem = $$v
                                              },
                                              expression: "repeatItem",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.repeatItem == "weekly" ||
                              _vm.repeatItem == "monthly"
                                ? _c(
                                    "div",
                                    { staticClass: "repeat mb-5" },
                                    [
                                      _c("strong", { staticClass: "mb-2" }, [
                                        _vm._v(" Repetir: "),
                                      ]),
                                      _vm.repeatItem == "weekly"
                                        ? _c(
                                            "v-btn-toggle",
                                            {
                                              attrs: {
                                                rules: [
                                                  (v) =>
                                                    !!v || "Campo obrigatório",
                                                ],
                                                color: "primary",
                                                dense: "",
                                                multiple: "",
                                              },
                                              model: {
                                                value: _vm.toggle_week,
                                                callback: function ($$v) {
                                                  _vm.toggle_week = $$v
                                                },
                                                expression: "toggle_week",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    depressed: "",
                                                    fab: "",
                                                    rounded: "",
                                                    "x-small": "",
                                                  },
                                                },
                                                [_vm._v(" D ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    depressed: "",
                                                    fab: "",
                                                    rounded: "",
                                                    "x-small": "",
                                                  },
                                                },
                                                [_vm._v(" S ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    depressed: "",
                                                    fab: "",
                                                    rounded: "",
                                                    "x-small": "",
                                                  },
                                                },
                                                [_vm._v(" T ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    depressed: "",
                                                    fab: "",
                                                    rounded: "",
                                                    "x-small": "",
                                                  },
                                                },
                                                [_vm._v(" Q ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    depressed: "",
                                                    fab: "",
                                                    rounded: "",
                                                    "x-small": "",
                                                  },
                                                },
                                                [_vm._v(" Q ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    depressed: "",
                                                    fab: "",
                                                    rounded: "",
                                                    "x-small": "",
                                                  },
                                                },
                                                [_vm._v(" S ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    depressed: "",
                                                    fab: "",
                                                    rounded: "",
                                                    "x-small": "",
                                                  },
                                                },
                                                [_vm._v(" S ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.repeatItem == "monthly"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  attrs: {
                                                    justify: "space-between",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "5" } },
                                                    [
                                                      _vm.repeatItem ==
                                                      "monthly"
                                                        ? _c("z-input", {
                                                            ref: "inptRef",
                                                            staticClass:
                                                              "custom_inpt mt-4",
                                                            attrs: {
                                                              "hide-details": true,
                                                              rules: [
                                                                (v) =>
                                                                  !!v ||
                                                                  "Campo obrigatório",
                                                              ],
                                                              itemText: "text",
                                                              label: "dia",
                                                              itemValue:
                                                                "value",
                                                              max: "31",
                                                              min: "1",
                                                              type: "number",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.toggle_month_choose,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.toggle_month_choose =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "toggle_month_choose",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.repeatItem !== "minutely" &&
                              _vm.repeatItem !== "hourly"
                                ? _c(
                                    "div",
                                    { staticClass: "execution mb-1" },
                                    [
                                      _c("strong", { staticClass: "mb-2" }, [
                                        _vm._v(" Horário de execução "),
                                      ]),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "6",
                                                sm: "6",
                                              },
                                            },
                                            [
                                              _c("z-select", {
                                                attrs: {
                                                  items: _vm.hoursArr,
                                                  hideDetails: "",
                                                  label: "Hora",
                                                },
                                                model: {
                                                  value: _vm.hourInfo,
                                                  callback: function ($$v) {
                                                    _vm.hourInfo = $$v
                                                  },
                                                  expression: "hourInfo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "6",
                                                sm: "6",
                                              },
                                            },
                                            [
                                              _c("z-select", {
                                                attrs: {
                                                  items: _vm.minutesArr,
                                                  hideDetails: "",
                                                  label: "Minuto",
                                                },
                                                model: {
                                                  value: _vm.minuteInfo,
                                                  callback: function ($$v) {
                                                    _vm.minuteInfo = $$v
                                                  },
                                                  expression: "minuteInfo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "content__timer-type mt-5" },
                                [
                                  _c("strong", { staticClass: "mb-2" }, [
                                    _vm._v(
                                      " Quando um usuário já estiver na jornada "
                                    ),
                                  ]),
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: {
                                        rules: [
                                          (v) => !!v || "Campo obrigatório",
                                        ],
                                        "hide-details": "",
                                        inline: "",
                                      },
                                      model: {
                                        value: _vm.newUserJourneyEntryBehavior,
                                        callback: function ($$v) {
                                          _vm.newUserJourneyEntryBehavior = $$v
                                        },
                                        expression:
                                          "newUserJourneyEntryBehavior",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        staticStyle: { "margin-top": "10px" },
                                        attrs: { value: "createNew" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#000000",
                                                      },
                                                    },
                                                    [_vm._v("Criar novamente")]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          4039041847
                                        ),
                                      }),
                                      _c("v-radio", {
                                        staticStyle: { "margin-top": "10px" },
                                        attrs: { value: "noChange" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#000000",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Não criar um novo e não alterar o existente"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          348081320
                                        ),
                                      }),
                                      _c("v-radio", {
                                        staticStyle: { "margin-top": "10px" },
                                        attrs: { value: "backToBeginning" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#000000",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Não criar um novo e mover o existente para o início"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          22252182
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "content__timer-type mt-5" },
                        [
                          _c("strong", { staticClass: "mb-2" }, [
                            _vm._v(" Ao aguardar: "),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: {
                                rules: [(v) => !!v || "Campo obrigatório"],
                                "hide-details": "",
                                inline: "",
                              },
                              model: {
                                value: _vm.userWaitBehaviour,
                                callback: function ($$v) {
                                  _vm.userWaitBehaviour = $$v
                                },
                                expression: "userWaitBehaviour",
                              },
                            },
                            [
                              _c("v-radio", {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { value: "noChange" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#000000" } },
                                          [_vm._v("Manter dados do inicio")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                              _c("v-radio", {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { value: "updateData" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#000000" } },
                                          [_vm._v("Atualizar dados")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "content__timer-type mt-5" }, [
                        _c("strong", { staticClass: "mb-2" }, [
                          _vm._v(" Configuração de envio em duplicidade "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "repeat-each mb-3" },
                          [
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "12",
                                      lg: "12",
                                      md: "12",
                                      sm: "12",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { "max-width": "700", top: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "z-checkbox",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              "hide-details":
                                                                "",
                                                              label:
                                                                "Não permitir o envio para o mesmo número e e-mail na mesma jornada",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.shouldSentMensageriaToExistentContact,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.shouldSentMensageriaToExistentContact =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "shouldSentMensageriaToExistentContact",
                                                            },
                                                          },
                                                          "z-checkbox",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              padding: "1rem",
                                              "font-weight": "500",
                                              display: "block",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " Essa opcao impede que sejam enviados dois emails/sms para usuarios que por acaso tenham o mesmo email ou telefone cadastrado na base, caso a sua base nao tenha emails/sms duplicados, isso nao e necessario e afetaria a velocidade desnecessariamente "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.shouldSentMensageriaToExistentContact
                                      ? _c(
                                          "small",
                                          { staticStyle: { color: "red" } },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                "A opção de envio em duplicidade pode afetar a velocidade no envio das mensagens"
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "content__timer-type mt-5" }, [
                        _c("strong", { staticClass: "mb-2" }, [
                          _vm._v(" Configuração de optout "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "repeat-each mb-3" },
                          [
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "12",
                                      lg: "12",
                                      md: "12",
                                      sm: "12",
                                    },
                                  },
                                  [
                                    _c("z-checkbox", {
                                      attrs: {
                                        "hide-details": "",
                                        label: "Ignorar optout",
                                      },
                                      model: {
                                        value: _vm.ignoreOptout,
                                        callback: function ($$v) {
                                          _vm.ignoreOptout = $$v
                                        },
                                        expression: "ignoreOptout",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "content__timer-type mt-5" }, [
                        _c("strong", { staticClass: "mb-2" }, [
                          _vm._v(" Ignorar usuarios nas seguintes jornadas:"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "repeat-each mb-3" },
                          [
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "12",
                                      lg: "12",
                                      md: "12",
                                      sm: "12",
                                    },
                                  },
                                  [
                                    _c("z-autocomplete", {
                                      attrs: {
                                        hideDetails: true,
                                        items: _vm.journeys,
                                        itemText: "name",
                                        itemValue: "id",
                                        multiple: "",
                                        placeholder: "",
                                        type: "text",
                                      },
                                      model: {
                                        value: _vm.ignoreJourneys,
                                        callback: function ($$v) {
                                          _vm.ignoreJourneys = $$v
                                        },
                                        expression: "ignoreJourneys",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "content__timer-type mt-5" }, [
                        _c("strong", { staticClass: "mb-2" }, [
                          _vm._v(" Emails para notificar na finalização:"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "repeat-each mb-3" },
                          [
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "12",
                                      lg: "12",
                                      md: "12",
                                      sm: "12",
                                    },
                                  },
                                  [
                                    _c("z-input", {
                                      attrs: { "hide-details": true },
                                      model: {
                                        value: _vm.finishNotificationEmails,
                                        callback: function ($$v) {
                                          _vm.finishNotificationEmails = $$v
                                        },
                                        expression: "finishNotificationEmails",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pt-5 pb-1 d-flex justify-end" },
                    [
                      _c("z-btn", {
                        staticClass: "mr-2",
                        attrs: { text: "Cancelar", rounded: "" },
                        on: {
                          click: function ($event) {
                            _vm.confirmationFastJourney = false
                          },
                        },
                      }),
                      _c("z-btn", {
                        attrs: {
                          text: "Confirmar",
                          primary: "",
                          rounded: "",
                          disabled: !_vm.choosenAudience,
                        },
                        on: { click: _vm.createSimpleJourney },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.confirmationFastJourney,
          callback: function ($$v) {
            _vm.confirmationFastJourney = $$v
          },
          expression: "confirmationFastJourney",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("span", [_vm._v("CRIE, SELECIONE OU IMPORTE UM TEMPLATE")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }